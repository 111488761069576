/*
 * Skin: mitpv
 * ----------
 */
.skin-mitpv {
  .main-header .navbar {
    background-color: #2bba68;
  }
  .main-header .navbar .nav > li > a {
    color: #ffffff;
  }
  .main-header .navbar .nav > li > a:hover,
  .main-header .navbar .nav > li > a:active,
  .main-header .navbar .nav > li > a:focus,
  .main-header .navbar .nav .open > a,
  .main-header .navbar .nav .open > a:hover,
  .main-header .navbar .nav .open > a:focus,
  .main-header .navbar .nav > .active > a {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
  }
  .main-header .navbar .sidebar-toggle {
    color: #ffffff;
  }
  .main-header .navbar .sidebar-toggle:hover {
    color: #f6f6f6;
    background: rgba(0, 0, 0, 0.1);
  }
  .main-header .navbar .sidebar-toggle {
    color: #fff;
  }
  .main-header .navbar .sidebar-toggle:hover {
    background-color: #2bba68;
  }
  @media (max-width: 767px) {
    .main-header .navbar .dropdown-menu li.divider {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .main-header .navbar .dropdown-menu li a {
      color: #fff;
    }
    .main-header .navbar .dropdown-menu li a:hover {
      background: #2bba68;
    }
  }
  .main-header .logo {
    background-color: #2bba68;
    color: #ffffff;
    border-bottom: 0 solid transparent;
  }
  .main-header .logo:hover {
    //background-color: #357ca5;
  }
  .main-header li.user-header {
    background-color: #3c8dbc;
  }
  .content-header {
    background: transparent;
  }
  .wrapper,
  .main-sidebar,
  .left-side {
    background-color: #222d32;
  }
  .user-panel > .info,
  .user-panel > .info > a {
    color: #fff;
  }
  .sidebar-menu > li.header {
    color: #4b646f;
    background: #1a2226;
  }
  .sidebar-menu > li > a {
    border-left: 3px solid transparent;
  }
  .sidebar-menu > li:hover > a,
  .sidebar-menu > li.active > a {
    color: #ffffff;
    background: #1e282c;
    border-left-color: #3c8dbc;
  }
  .sidebar-menu > li > .treeview-menu {
    margin: 0 1px;
    background: #2c3b41;
  }
  .sidebar a {
    color: #b8c7ce;
  }
  .sidebar a:hover {
    text-decoration: none;
  }
  .treeview-menu > li > a {
    color: #8aa4af;
  }
  .treeview-menu > li.active > a,
  .treeview-menu > li > a:hover {
    color: #ffffff;
  }
  .sidebar-form {
    border-radius: 3px;
    border: 1px solid #374850;
    margin: 10px 10px;
  }
  .sidebar-form input[type="text"],
  .sidebar-form .btn {
    box-shadow: none;
    background-color: #374850;
    border: 1px solid transparent;
    height: 35px;
  }
  .sidebar-form input[type="text"] {
    color: #666;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }
  .sidebar-form input[type="text"]:focus,
  .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
    background-color: #fff;
    color: #666;
  }
  .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
    border-left-color: #fff;
  }
  .sidebar-form .btn {
    color: #999;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
  layout-top-nav .main-header > .logo {
    background-color: #3c8dbc;
    color: #ffffff;
    border-bottom: 0 solid transparent;
  }
  layout-top-nav .main-header > .logo:hover {
    background-color: #3b8ab8;
  }


  .small-box .icon{
    top: 10px;
    right: 10px;
    z-index: 0;
    font-size: 60px;
  }
}