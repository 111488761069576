img {
  max-width: 100%;
}
.logo-lg {
  img {
    height: 50px;
  }
}

.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events:none;
}

.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}

#idImagen {
  display: block;
  margin: 30px auto;
}

@media (min-width: 1200px) {
  .col-lg-3.card {
    height: 171px;
  }
}

.card.card-glop {
  .small-box {
    background-color: #ffffff;
    color: #2bba68;
    &:hover .icon {
      color: #2bba68;
      font-size: 60px;
    }
     > a.small-box-footer:hover {
       background: #d9d9d9;
       color: #2bba68;
     }
    > div.small-box-footer:hover {
      background-color: #2bba68;
    }
  }
  .small-box-footer {
    background-color: #2bba68;
  }
  .icon {
    color: #d9d9d9;
  }
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #2bba68;
  border-color: #2bba68;
}

.bg-glop {
  background-color: #2bba68 !important;
  color: white !important;
}

.c-glop {
  color: #2bba68 !important;
}

.btn-primary.btn-refrescar {
  background-color: #ff7e00;
  border-color: #ff7e00;
  &:hover {
    background-color: #2bba68;
    border-color: #2bba68;
  }
}

#autorefresh {
  margin-top: 10px;
  margin-right: 10px;
}