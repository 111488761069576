@media screen and (min-width: 700px) {
  body, ul, li {
    line-height: 21px;
  }
  #referencia {
    color: #fff;
  }
  #playImagen {
    height: 60px;
    width: 200px;
  }
  #textoF {
    display: none;
  }
  body.login-page2 {
    background-image: url('../img/fotolia_57793221.jpg');
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    border: 0;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    color: black;
  }
  #menu {
    list-style: none;
    width: 940px;
    margin: 30px auto 0px auto;
    height: 43px;
    padding: 0px 20px 0px 20px;
    border-radius: 10px;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#94B413), to(#94B413));
    border: 1px solid #002232;
    box-shadow: inset 0px 0px 1px #edf9ff;
  }
  #menu li {
    float: left;
    text-align: center;
    position: relative;
    padding: 4px 10px 4px 10px;
    margin-right: 9%;
    margin-top: 7px;
    border: none;
  }
  #menu li:hover {
    border: 1px solid #777777;
    padding: 4px 9px 4px 9px;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F4F4F4), to(#EEEEEE));
    border-radius: 5px 5px 5px 5px;
  }
  #menu li a {
    font-family: 'Abel', sans-serif;
    font-size: 14px;
    color: #EEEEEE;
    display: block;
    outline: 0;
    text-decoration: none;
    text-shadow: 1px 1px 1px #000;
  }
  #menu li:hover a {
    color: #161616;
    text-shadow: 1px 1px 1px #FFFFFF;
  }
  //#idImagen {
  //  margin: 0px 7%;
  //  margin-top: 2%;
  //  margin-bottom: 3%;
  //  float: left;
  //}
  //@media (max-width: 816px) {
  //  #divGeneral {
  //    margin-right: 12%;
  //  }
  //  form.login {
  //    margin-top: -1%;
  //  }
  //}
  //@media (min-width: 816px) {
  //  #divGeneral {
  //    margin-right: 19%;
  //  }
  //  form.login {
  //    margin-top: -6%;
  //  }
  //  .error {
  //    margin-top: -1.5%;
  //    margin-left: 45%;
  //  }
  //}
  #primeraCaja {
    width: 100%;
    height: 10%;
  }
  #consultaRemota {
    padding: 3px;
    color: #FFFFFF;

    background-color: #94B413;
  }
  //#divH2 {
  //  margin-top: -10%;
  //  margin-left: 30%;
  //}
  //#divGeneral {
  //  float: left;
  //  width: 35%;
  //  margin-left: 7%;
  //}
  //#divTextoP {
  //  margin-right: 11%;
  //}
  #textoP {
    text-align: justify;
    font-family: 'Abel', sans-serif;
  }
  #textoH1 {
    color: #ffffff;
    width: 100%;
    margin: 0px 15%;
    margin-bottom: 3%;
    float: left;
    margin-top: -6%;
    font-family: 'Abel', sans-serif;
  }
  form.login {
    font-family: 'Abel', sans-serif;
    background-color: rgba(255, 255, 255, 0.7);
    float: left;
    padding: 20px;
    width: 278px;
    box-shadow: 0px 0px 20px black;
    border-radius: 10px;
  }
  form.login div {
    overflow: hidden;
  }
  form.login div label {
    display: block;
    float: left;
    line-height: 25px;
  }
  form.login div input[type="text"], form.login div input[type="password"] {
    border: 1px solid #DCDCDC;
    float: right;
    padding: 4px;
  }
  form.login div input[name="login"], form.login div input[name="consultar"], form.login div input[name="crearDB"] {
    background-color: #94B413;
    width: auto !important;
    display: inline-block;
    cursor: pointer;
    line-height: normal;
    border: none !important;
    border-radius: 3px !important;
    color: #fff !important;
    padding: 6px 15px !important;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
    box-shadow: none !important;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-repeat: repeat-x;
    margin-left: 37%;
    font-weight: bold;
  }
  form.login div input[name="login"] {
    margin-top: 5%;
    color: #FFFFFF;
  }
  form.login div input[name="insertar"] {
    background: none repeat scroll 0 0 #DEDEDE;
    border: 1px solid #C6C6C6;
    margin-left: 37%;
    font-weight: bold;
    padding: 4px 25px;
  }
  form.login div input[name="eliminar"] {
    background: none repeat scroll 0 0 #DEDEDE;
    border: 1px solid #C6C6C6;
    margin-left: 37%;
    font-weight: bold;
    padding: 4px 23px;
  }
  form.login div input[name="btnPass"] {
    background: none;
    border: 0px;
    margin-left: 20%;
  }
  form.login div input[name="btnAtras"] {
    background: none;
    border: 0px;
    margin-left: 43%;
  }
  .error {
    color: red;
    font-weight: bold;
    text-align: center;
  }
  #desplegableLista {
    margin-left: 40px;
  }
}

@media screen and (max-width: 700px) {
  body, ul, li {
    line-height: 21px;
  }
  #playImagen {
    height: 60px;
    width: 200px;
  }
  #menu {
    list-style: none;
    width: 315px;
    margin: 30px auto 0px auto;
    height: 43px;
    padding: 0px 20px 0px 20px;
    border-radius: 10px;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#94B413), to(#94B413));
    border: 1px solid #002232;
    box-shadow: inset 0px 0px 1px #edf9ff;
  }
  body {
    background-image: url('../img/123.jpg');
    background-repeat: no-repeat;
  }
  #menu li {
    float: left;
    position: relative;
    margin-right: 2%;
    margin-top: 7px;
    border: none;
  }
  #menu li a {
    font-family: 'Abel', sans-serif;
    font-size: 10px;
    color: #EEEEEE;
    display: block;
    outline: 0;
    text-decoration: none;
    text-shadow: 1px 1px 1px #000;
  }
  form.login {
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid #DDDDDD;
    font-family: 'Abel', sans-serif;
    margin: 0 auto;
    padding: 20px;
    width: 278px;
    box-shadow: 0px 0px 20px black;
    border-radius: 10px;
  }

  #primeraCaja {
    width: 100%;
    height: 3%;
  }
  #consultaRemota {
    display: none;
  }
  #textoP {
    display: none;
  }
  #referencia {
    color: #000;
  }
  #textoF {
    margin-left: 35%;
  }
  #divH1 {
    margin-left: 22%;
  }
  @media only screen and (max-width: 441px) {
    #divH1 {
      margin-left: 5%;
    }
    #textoF {
      margin-left: 30%;
    }
  }
  @media only screen and (max-width: 512px) and (min-width: 441px) {
    #divH1 {
      margin-left: 14%;
    }
    #textoF {
      margin-left: 33%;
    }
  }
  #textoH1 {
    color: #ffffff;
    font-size: 30px;
    font-family: 'Abel', sans-serif;
  }
  form.login div {
    margin-bottom: 15px;
    overflow: hidden;
  }
  form.login div label {
    display: block;
    float: left;
    line-height: 25px;
  }
  form.login div input[type="text"], form.login div input[type="password"] {
    border: 1px solid #DCDCDC;
    float: right;
    padding: 4px;
  }
  form.login div input[name="login"], form.login div input[name="consultar"], form.login div input[name="crearDB"], form.login div input[name="nuevo"] {
    background-color: #94B413;
    width: auto !important;
    display: inline-block;
    cursor: pointer;
    line-height: normal;
    border: none !important;
    border-radius: 3px !important;
    color: #fff !important;
    padding: 6px 15px !important;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
    box-shadow: none !important;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-repeat: repeat-x;
    margin-left: 37%;
    font-weight: bold;
  }
  form.login div input[name="insertar"] {
    background-color: #94B413;
    width: auto !important;
    display: inline-block;
    cursor: pointer;
    line-height: normal;
    border: none !important;
    border-radius: 3px !important;
    color: #fff !important;
    padding: 6px 15px !important;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
    box-shadow: none !important;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-repeat: repeat-x;
    margin-left: 37%;
    font-weight: bold;
  }
  form.login div input[name="eliminar"] {
    background-color: #94B413;
    width: auto !important;
    display: inline-block;
    cursor: pointer;
    line-height: normal;
    border: none !important;
    border-radius: 3px !important;
    color: #fff !important;
    padding: 6px 15px !important;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
    box-shadow: none !important;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    background-repeat: repeat-x;
    margin-left: 37%;
    font-weight: bold;
  }
  form.login div input[name="btnPass"] {
    background: none;
    border: 0px;
    margin-left: 20%;
  }
  form.login div input[name="btnAtras"] {
    background: none;
    border: 0px;
    margin-left: 43%;
  }
  .error {
    color: red;
    font-weight: bold;
    margin: 10px;
    text-align: center;
  }
  #desplegableLista {
    margin-left: 40px;
  }
}

.login {
  font-family: 'Abel', sans-serif;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  box-shadow: 0px 0px 20px black;
  border-radius: 10px;
}